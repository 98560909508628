import { PickerInline } from 'filestack-react';
import { useErrorAlert } from 'components/Error/logError';
import { useToast, closeToast, Dialog } from '@hygraph/baukasten';
import { CommonFilestack } from 'generated/graphql';
import { getAssetClientConfig, fetchFileMetadata } from 'modules/assets';
import { trans } from 'i18n';
import { AssetConfigFragment } from 'modules/assets/gql/generated/AssetConfigFragment';

interface AssetType {
  handle: string;
  id: string;
  fileName: string;
  mimeType?: string | null;
  size: number;
  width: number;
  height: number;
}

export interface FilestackUploaderProps {
  assetConfig: AssetConfigFragment | CommonFilestack;
  maxFiles?: number;
  onAssetCreation?: (assets: AssetType[]) => void;
  onUploaded: (fileData: AssetType) => Promise<void>;
  onUploading: (loading: boolean) => void;
  dataCy?: string;
  filestackOptions?: any;
}

interface HandleUploadTypes {
  // UNFIXABLE: IIRC we cannot be sure how those are typed without filestack-react having an up to date type definition file.
  filesUploaded: any[];
  filesFailed: any[];
}

export const FilestackUploader = ({
  assetConfig,
  maxFiles,
  onUploaded,
  onUploading,
  filestackOptions,
  onRequestClose,
}: FilestackUploaderProps & { onRequestClose: () => void }) => {
  const toast = useToast();
  const triggerErrorAlert = useErrorAlert({
    errorMessage: trans('Asset upload failed'),
  });
  const { clientOptions, clientMetadata } = getAssetClientConfig(assetConfig);

  const handleUpload = ({ filesUploaded, filesFailed }: HandleUploadTypes) => {
    onUploading(true);
    const toastId = toast({
      variantColor: 'info',
      description: trans('Processing Files'),
      duration: false,
      isClosable: false,
    });
    if (filesFailed.length > 0) {
      triggerErrorAlert(new Error(trans('Asset upload failed')));
      closeToast(toastId);
      onUploading(false);
      return;
    }

    Promise.all(
      filesUploaded.map(file =>
        fetchFileMetadata(file.handle, clientMetadata)
          // UNFIXABLE: IIRC we cannot be sure how this is typed without filestack-react having an up to date type definition file.
          // eslint-disable-next-line
          .then((newData: any) => ({
            ...file,
            ...newData,
          }))
          .then(onUploaded)
      )
    )
      .then(() => closeToast(toastId))
      .then(onRequestClose)
      .finally(() => onUploading(false));
  };

  if (
    assetConfig.__typename !== 'Filestack' &&
    assetConfig.__typename !== 'CommonFilestack'
  ) {
    throw new Error('AssetConfig is not of type Filestack or CommonFilestack');
  }

  return (
    <Dialog isOpen maxWidth="750px" onDismiss={onRequestClose}>
      <PickerInline
        apikey={assetConfig.apiKey}
        pickerOptions={{
          maxFiles: maxFiles || 50,
          storeTo: {
            path: assetConfig.path,
          },
          allowManualRetry: true,
          ...filestackOptions,
        }}
        onSuccess={handleUpload}
        onError={triggerErrorAlert}
        clientOptions={clientOptions}
      />
    </Dialog>
  );
};
